<template>
  <div class="questions">
    <Question
      v-for="(question, index) in questions"
      :key="index"
      :question="question"
      class="questions__item"
    />
  </div>
</template>

<script>
import './questions.scss';
import Question from './Question';

export default {
  name: 'Questions',
  components: {
    Question
  },
  data() {
    return {
      questions: [
        {
          title: 'Что такое отчет об объекте недвижимости?',
          desc:
            'тчет об объекте недвижимости - это документ, подтверждающий актуальные сведения о собственниках, кадастровой стоимости, возникновении и переходе прав на собственность. В ней представлена справка о недвижимом имуществе.'
        },
        {
          title:
            'Могу ли я онлайн получить отчет по данным из ЕГРН на недвижимость в другом городе?',
          desc:
            'Да, сведения доступны любому гражданину России. Ваше местонахождение и прописка не влияет на доступ к информации о любой недвижимости на территории РФ.'
        },
        {
          title: 'Чем отличается ЕГРН от ЕГРП и ГКН?',
          desc:
            'В ЕГРН входят ЕГРП (реестр прав на недвижимость) И ГКН (кадастр недвижимости). Благодаря объединению баз данных исключена возможность несовпадений и дублирования информации.'
        },
        {
          title: 'Как долго ждать отчет и от чего это зависит?',
          desc:
            'В большинстве случаев он приходит на почту в течение 15-30 минут. Скорость определяется загруженностью серверов официального источника — Росреестра. В редких случаях срок может составить до 3 дней. '
        },
        {
          title:
            'Могу ли я узнать, сколькими объектами недвижимости владеет гражданин РФ?',
          desc:
            '     Такое право имеет только сам гражданин или его представитель. Также данные могут предоставляться по решению суда. '
        },
        {
          title: 'Как узнать, сколько раз перепродавалась недвижимость?',
          desc:
            '     Чтобы получить детальную историю действий с объектом, закажите отчет «О переходе прав собственности...». '
        },
        {
          title: 'Не могу найти собственника в отчете, полученном онлайн?',
          desc:
            'Причиной может быть деление объекта на несколько долей, которыми владеет несколько собственников. Также в отчете может не оказаться данных, если недвижимость была зарегистрирована до 31 января 1998 года. Один объект может ошибочно получить несколько кадастровых номеров — в этом случае сведения о нем будут недоступны. Если собственник получил права владения объектом несколько дней назад, информация о нем может быть недоступна. '
        }
      ]
    };
  }
};
</script>
