<template>
  <div
    class="question"
    :class="{
      question_opened: show
    }"
  >
    <span class="question__toggle" @click="toggle"></span>
    <p class="question__title" @click="toggle">
      {{ question.title }}
    </p>
    <div v-if="show" class="question__desc">
      {{ question.desc }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    }
  }
};
</script>
